.container {
  margin-bottom: 20px;
}

.comments_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  margin-top: 5px;
  width: 100%;
}

.article_container {
  margin-top: 20px;
}

.comments_item > div {
  text-align: center;
}

.comments_header {
  background-color: #005FAA;
  color: white;
}

.comments_author {
  width: 100px;
}

.articles_title {
  width: 200px;
}

.articles_preview {
  width: 350px;
}

.articles_buttons {
  width: 100px;
}
