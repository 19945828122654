.page {
  padding: 10px;
  width: 100%;
}

.editor_container {
  border: 1px solid #D4D4D4;
  background-color: white;
  padding: 5px;
  line-height: normal !important;
  color: black;
  font-size: 18px;
}

.editor {
  font-size: 18px;
  min-height: 250px;
}

.custom {
  border: 1px solid orange;
}

.label {
  font-size: 12pt;
  display: block;
  color: black;
}

.title {
  font-size: 15pt;
  margin: 0 0 30px 0;
  font-weight: bold;
}
 


