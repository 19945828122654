.container {
  margin-top: 30px;
  margin-bottom: 40px;
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 22px;
}

.button {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  display: block;
}

.news_button {
  width: 195px;
  height: 35px;
  background: linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%);
  color: #FBFBFB;
}

.news_container {
  display: flex;
  justify-content: space-around;
}

.news_item {
  margin-top: 30px;
  width: 245px;
  height: 245px;
  overflow: hidden;
  position: relative;
}

.news_item img {
  width: 300px;
}

.image_filter {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #005FAA;
  opacity: 0.4;
}

.info {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
}

.info_date {
  text-align: right;
}
.info_title {
  font-size: 16px;
  line-height: 20px;
}
