.main {
  width: 100%;
  font-size: 14pt;
  flex: auto;
  margin-top: 5px;
  background-color: #F2F6FF;
}

.main_content {
  background-color: #F2F6FF;
  height: 100%;
  min-width: 800px;
  max-width: 1100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

