.control {
  color: black;
  text-align: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 50px;
}

.title {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
}

.text {
  font-size: 18px;
  padding: 15px;
  text-align: left;
}

.lside {
  width: 100%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rside {
  height: 70%;
}

.rside_image {
  height: 290px;
  overflow: hidden;
}

.rside_image img {
  background-size: 500px;
  width: 500px;
}