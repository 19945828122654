.profile_navigation {
  display: flex;
}

.content_container {
  margin-top: 20px;
}


.label {
  font-size: 20px;
  color: black;
}

.title {
  font-size: 15pt;
  margin: 0 0 30px 0;
  font-weight: bold;
  display: block;
}
