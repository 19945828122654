.article {
  padding: 5px;
  color: black;
  border: 1px solid #8EABC2;
  display: flex;
}

.title {
  font-weight: bold;
}

.poster {
  width: 300px;
  margin-right: 20px;
}

.poster img {
  width: 300px;
}