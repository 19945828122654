.container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.title_text {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

.rasp {
  margin-top: 20px;
}

.day_container {
  margin-top: 15px;
}

.rasp_day_title {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

.rasp_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  margin-top: 10px;
}

.rasp_item_number, .rasp_item_place {
  width: 150px;
  text-align: center;
}

.rasp_item_text {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.rasp_header {
  background-color: #005FAA;
  color: white;
}
