.footer {
  width: 100%;
  background-color: #005FAA;
  color: white;
}

.footer_content {
  background-color: #005FAA;
  padding: 20px 5px 20px 5px;
  margin-left: auto;
  margin-right: auto;
  min-width: 800px;
  max-width: 1100px;
  width: 100%;
  font-size: 14pt;
}

.logo {
  width: 250px;
  background-size: 250px;
  height: 60px;
  background-image: url('./res/footer-logo.svg');
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contacts {
  min-width: 470px;
  width: 100%;
  height: 155px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact_item {
  width: 150px;
  height: 75px;
  font-size: 17px;
  line-height: 21px;
  font-weight: 300;
}

.contact_text {
  font-weight: 500;
  white-space: nowrap;
}

.address {
  height: 100%;
}

.long_text {
  margin-top: 5px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.social {
  font-size: 20px;
  font-weight: 500;
}

.button {
  width: 32px;
  height: 32px;
  border: none;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.button_vk {
  background-image: url('./res/vk.svg');
  background-size: 32px;
}

.button_instagram {
  background-image: url('./res/instagram.svg');
  background-size: 32px;
}