.news_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
}
.news_container_title {
  margin: 40px 0 40px 0;
  font-weight: 700;
  font-size: 27px;
}

.skeleton {
  border: 1px solid #8EABC2;
  padding: 10px;
}

.articles {
  border: 1px solid #F2F2F2;
  width: 800px;
}
.articles > a:nth-child(n+2) > div,
.skeleton:nth-child(n+2) {
  margin-top: 10px;
}

.aside {
  margin-left: 10px;
  width: 300px;
  border: 1px solid #F2F2F2;
  background-color: #005FAA;
  color: #FBFBFB;
  padding: 10px;
}
.aside_title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.aside_post {
  margin-top: 15px;
}

.title {
  font-size: 16pt;
  color: #FBFBFB;
}

.link_color, .link_color:hover {
  color: #FBFBFB;
}

.target_new {
  margin-top: 50px;
}
.target_container {
  padding: 5px;
  width: 100%;
}

.target_aside {
  min-width: 300px;
}
.target_title {
  font-weight: 700;
  font-size: 24px;
}
.target_html {
  margin-top: 20px;
}
.target_html img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
