
.login_page {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  display: flex;
  justify-content: center;
}

.form_container {
  width: 500px;
  padding: 20px;
}

.bigger {
  font-size: 14pt;
}

.label {
  font-size: 12pt;
  display: block;
  color: black;
  
}

.title {
  font-size: 15pt;
  margin: 0 0 30px 0;
  font-weight: bold;
}

form input {
  background-color: transparent;
}