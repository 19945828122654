.target_new {
  margin-top: 50px;
}
.target_container {
  padding: 5px;
}
.target_aside {
  min-width: 300px;
}
.target_title {
  font-weight: 700;
  font-size: 24px;
}
.target_html {
  margin-top: 20px;
}
.target_html img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.comment {
  margin-top: 10px;
}
.comment_title {
  color: #005FAA;
  font-size: 20px;
}
.comment_text {
  font-size: 18px;
}
.counter {
  color: #005FAA;
}
.comments_count {
  font-weight: 400;
}


.comment_date {
  color: #8EABC2;
  font-size: 16px;
}

.form_controls {
  display: flex;
  justify-content: right;
}

.comment_form_container {
  margin-top: 20px;
}
.comment_textarea {
  border: 1px solid #8EABC2;
  resize: none;
}
.comment_textarea:focus {
  box-shadow: none !important;
  border: 1px solid #005FAA;
}
.comment_textarea:hover {
  border: 1px solid #005FAA;
}

.comments_count h2 {
  font-size: 22px;
  font-weight: 500;
  display: block;
  border-bottom: 1px solid #8EABC2;
}

.comment_form_container h2 {
  font-size: 22px;
  font-weight: 500;
}
