.header {
  width: 100%;
  background-color: #F2F6FF;
}

.header_content {
  background-color: #F2F6FF;
  color: white;
  font-size: 14pt;
  min-width: 800px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  height: 86px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}

.title {
  width: 350px;
}

.logo {
  width: 300px;
  background-size: 300px;
  height: 72px;
  background-image: url('./res/logo.svg');
  content: ' ';
}

.button {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  display: block;
}

.login_button {
  width: 92px;
  height: 36px;
  color: #005FAA;
}

.register_button {
  width: 195px;
  height: 35px;
  background: linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%);
  color: #FBFBFB;
}
.register_button:hover {
  color: #FBFBFB;
}

.rightside {
  display: flex;
}