.container {
  margin-top: 20px;
  color: #005FAA;
}

.big_text {
  font-size: 100px;
  font-weight: 700;
}

.label {
  font-size: 24px;
  font-weight: 700;
}

.button_container {
  margin-top: 20px;
}

.button {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  display: block;
}

.home_button {
  width: 300px;
  height: 35px;
  background-color: #005FAA;
  color: #FBFBFB;
}

.home_button:hover {
  color: #FBFBFB;
}