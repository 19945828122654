.navigation {
  border-bottom: 1px solid #D5DDDF;
  background-color: #005FAA;
  font-size: 14pt;
}

.content {
  background-color: #005FAA;
  height: 50px;
  min-width: 800px;
  max-width: 1100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  padding: 0 10px 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: #FBFBFB;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
  margin-top: 2px;
  height: 49px;
}
.link_active, .link:hover {
  background-color: #F2F6FF;
  color: #005FAA;
}
