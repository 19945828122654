.feedback_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  margin-top: 5px;
}

.feedback_item > div {
  text-align: center;
}

.feedback_header {
  background-color: #005FAA;
  color: white;
}

.feedback_id {
  width: 40px;
}

.feedback_name {
  width: 129px;
}

.feedback_email {
  width: 250px;
}

.feedback_text {
  flex: auto;
}

.feedback_data {
  width: 150px;
}