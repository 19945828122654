/* body {
  margin: 0;
  padding: 0;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
 */

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  background-color: #F2F2F2;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
}

.ce-block__content, 
.ce-toolbar__content {
 max-width: 94%;  /* example value, adjust for your own use case */
}

.ant-carousel .slick-dots li button {
  background: #8EABC2;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #005FAA;
}

.ant-carousel .slick-dots li.slick-active,
.ant-carousel .slick-dots li 
{
  width: 20px;
}

.Toastify__toast--default {
  background: #FBFBFB;
  color: #005FAA;
}

.Toastify__progress-bar--default {
  background: #005FAA;
}

.ant-form-item-label label{
  font-size: 19px;
}
