.container {
  margin: 30px 0 30px 0;
}

.title {
  font-weight: 700;
  font-size: 26px;
}

.rasp_container {
  display: flex;
  margin-top: 20px;
}

.rasp_kurses {
  margin-right: 20px;
}

.rasp_kurses_item {
  color:white;
  background-color: #005FAA;
  width: 150px;
  text-align: center;
  padding: 7px;
  font-size: 24px;
  cursor: pointer;
}
.rasp_kurses_item_active {
  background-color: white;
  border: 1px solid #005FAA;
  color: #005FAA;
}

.rasp_groups_container {
  width: 100%;
}

.rasp_groups {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  gap: 10px;
}
.rasp_groups_item {
  padding: 7px;
  border: 2px solid #005FAA;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #005FAA;
  font-size: 22px;
}

.rasp_groups_item_active {
  background-color: white;
  border: 1px solid #005FAA;
  color: #005FAA;
}